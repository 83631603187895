import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";

export const SignOutComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const signOutAndRedirect = () => {
    signOut();
    navigate("/sign-in");
  };

  return (
    <>
      {isAuthenticated() ? (
        <button
          className="btn-outline signout-button"
          onClick={() => signOutAndRedirect()}
        >
          Sign Out
        </button>
      ) : (
        <></>
      )}
    </>
  );
};
