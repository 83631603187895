import * as yup from "yup";

import { BaseEntity } from "@/types";
import { Question } from "@/features/questions";

export type WatchParty = {
  text: string;
  imagePath: string;
  image?: File;
  score: number;
  startAt: string;
  endAt: string;
  stakeholdersEmails: { id: string; email: string }[];
  hasGrandPrize: boolean;
  grandPrizes?: GrandPrize[];
  questions: Question[];
} & BaseEntity;

export type WatchPartyKeys = keyof WatchParty;

export type GrandPrize = {
  name: string;
  description: string;
  price: string;
} & BaseEntity;

export type LuckyAnswer = {
  alternative: number;
  answerId: string;
  drawnIn: string;
  id: string;
  questionId: string;
  userId: string;
  userName: string;
};

export type Sweepstake = {
  questionId: string;
  text: string;
  imagePath: string;
  sweepstake: boolean;
  luckyAnswer?: LuckyAnswer;
};
export const watchPartyHandler = {
  startAt: (key: "startAt", watchParty: WatchParty, formData: FormData) => {
    const timestamp = isNaN(Number(watchParty[key]))
      ? new Date(watchParty[key]).getTime()
      : watchParty[key];
    formData.append(key, String(timestamp));
  },
  endAt: (key: "endAt", watchParty: WatchParty, formData: FormData) => {
    const timestamp = isNaN(Number(watchParty[key]))
      ? new Date(watchParty[key]).getTime()
      : watchParty[key];
    formData.append(key, String(timestamp));
  },
  stakeholdersEmails: (
    key: "stakeholdersEmails",
    watchParty: WatchParty,
    formData: FormData
  ) => {
    watchParty[key].forEach(
      (item: { id: string; email: string }, index: number) => {
        formData.append(`${key}[${index}]`, item.email);
      }
    );
  },
  grandPrizes: (
    key: "grandPrizes",
    watchParty: WatchParty,
    formData: FormData
  ) => {
    if (watchParty.hasGrandPrize === false) {
      return;
    }
    const grandPrize = watchParty[key];
    grandPrize?.forEach((item: GrandPrize, index: number) => {
      formData.append(`${key}[${index}][name]`, item.name);
      formData.append(`${key}[${index}][description]`, item.description);
      formData.append(`${key}[${index}][price]`, item.price);
    });
  },
  default: (
    key: keyof WatchParty,
    watchParty: WatchParty,
    formData: FormData
  ) => {
    formData.append(key, watchParty[key] as string);
  },
};

export type WatchPartyHandlerKeys = keyof typeof watchPartyHandler;

export const watchPartySchema = yup.object({
  text: yup.string().trim().required("The watch party title is required."),
  image: yup.mixed().required("The cover image is required."),
  score: yup.number().required("The score is required.").min(0),
  startAt: yup
    .string()
    .trim()
    .required("The watch party start time is required."),
  endAt: yup.string().trim().required("The watch party end time is required."),
  stakeholdersEmails: yup.array(
    yup.object({
      email: yup
        .string()
        .trim()
        .required("Recipient E-mail is required.")
        .email(),
    })
  ),
  hasGrandPrize: yup.boolean().required(),
  grandPrizes: yup
    .array(
      yup.object({
        name: yup.string(),
        description: yup.string(),
        price: yup.string(),
      })
    )
    .when("hasGrandPrize", {
      is: true,
      then: yup
        .array(
          yup.object({
            name: yup
              .string()
              .trim()
              .required("The grand prize name is required."),
            description: yup
              .string()
              .trim()
              .required("The grand prize description is required."),
            price: yup.string().required("The grand prize value is required."),
          })
        )
        .required("Grand Prizes are required."),
    }),
});
