import "./style.css";

type CheckboxProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name: string;
  id: string;
  checked: boolean;
  label: string;
};

export const Checkbox = ({
  onChange,
  className,
  name,
  id,
  checked,
  label,
}: CheckboxProps) => {
  return (
    <div className={`checkbox-control ${className}`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
