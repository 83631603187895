import { UseFormRegister, FieldError } from "react-hook-form";

import "./style.css";

type InputFieldProps = {
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  type?: "text" | "email" | "password" | "number";
  placeholder?: string;
  className?: string;
  registerName: any;
  register: UseFormRegister<any>;
  required: any;
  error?: FieldError;
  isInvalid?: boolean;
  min?: number;
  max?: number;
};

export const InputField = ({
  onKeyDown,
  type,
  className,
  placeholder,
  register,
  registerName,
  required,
  error,
  isInvalid,
  min,
  max,
}: InputFieldProps) => {
  return (
    <>
      <input
        min={min}
        max={max}
        type={type}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={`${className} form-input ${isInvalid ? "is-invalid" : ""}`}
        {...register(registerName, required)}
      />
      {error ? <p className="error-message">{error.message}</p> : <></>}
    </>
  );
};
