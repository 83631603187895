import {
  useFieldArray,
  UseFormRegister,
  Control,
  UseFormSetValue,
  FieldErrorsImpl,
} from "react-hook-form";

import { WatchParty } from "@/features/watchParty";

import "./style.css";

type WatchPartyGrandStakeholdersProps = {
  control: Control<WatchParty, any>;
  register: UseFormRegister<WatchParty>;
  setValue: UseFormSetValue<WatchParty>;
  errors: Partial<FieldErrorsImpl<WatchParty>>;
};

export const WatchPartyStakeholders = ({
  control,
  register,
  setValue,
  errors,
}: WatchPartyGrandStakeholdersProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "stakeholdersEmails",
  });

  const addNewStakeholder = () => {
    append({
      email: "",
      id: String(fields.length + 1),
    });
  };

  const removeStakeholder = (index: number) => {
    if (fields.length) {
      remove(index);
    }
  };

  const onMouseEnterStakeholder = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".stakeholders-grid-options");
    options?.classList.add("shown");
  };

  const onMouseLeaveStakeholder = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".stakeholders-grid-options");
    options?.classList.remove("shown");
  };

  return (
    <div className="stakeholders-wrapper">
      <button type="button" className="btn-outline" onClick={addNewStakeholder}>
        Add new E-mail
      </button>
      <table className="stakeholders-grid">
        <thead className="grid-header">
          <tr>
            <th colSpan={1}>E-mail</th>
          </tr>
        </thead>
        <tbody>
          {fields.length ? (
            fields.map((stakeholder, index) => (
              <tr
                key={stakeholder.id}
                id={`stakeholders_${stakeholder.id}`}
                onMouseEnter={() =>
                  onMouseEnterStakeholder(
                    index,
                    `stakeholders_${stakeholder.id}`
                  )
                }
                onMouseLeave={() =>
                  onMouseLeaveStakeholder(
                    index,
                    `stakeholders_${stakeholder.id}`
                  )
                }
              >
                <td colSpan={1}>
                  <div className="input-wrapper">
                    <input
                      type="email"
                      placeholder="Enter E-mail here"
                      className={`grid-input ${
                        errors.stakeholdersEmails &&
                        errors.stakeholdersEmails[index]?.email
                          ? "is-invalid"
                          : ""
                      }`}
                      {...register(`stakeholdersEmails.${index}.email`, {
                        required: true,
                      })}
                    />
                    {errors.stakeholdersEmails &&
                      errors.stakeholdersEmails[index]?.email && (
                        <p className="error-message">
                          {errors.stakeholdersEmails[index]?.email?.message}
                        </p>
                      )}
                  </div>
                  <div className="stakeholders-grid-options">
                    <button
                      type="button"
                      className="grid-option-btn delete"
                      onClick={() => removeStakeholder(index)}
                    >
                      Trash
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr className="no-items-found">
              <td colSpan={3}>No items found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
