import toast, { Toast as RHToast } from "react-hot-toast";

import closeIcon from "@/assets/img/fi-close-gray.svg";

import "./style.css";

interface IToastProps {
  t: RHToast;
  message: string;
}

export const Toast = ({ t, message }: IToastProps) => (
  <div className="toast-wrapper">
    {message}
    <button type="button" onClick={() => toast.dismiss(t.id)}>
      <img alt="closeIcon" className="closeIcon" src={closeIcon} />
    </button>
  </div>
);
