import moment from "moment";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToast } from "@/hooks/useToast";
import { DatePickerField } from "@/components/Form";
import { WatchPartyService } from "@/services/WatchPartyService";
import { WatchPartyGrandPrizes, WatchPartyStakeholders } from "../components";
import { ContentLayout } from "@/components/Layout/ContentLayout/ContentLayout";
import {
  WatchParty,
  watchPartyHandler,
  WatchPartyHandlerKeys,
  watchPartySchema,
} from "@/features/watchParty";
import {
  InputField,
  ImageInput,
  FormControl,
  FormGroup,
  Checkbox,
} from "@/components/Form";

import "../assets/style.css";

export const CreateWatchParty = () => {
  const navigate = useNavigate();
  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<WatchParty>({ resolver: yupResolver(watchPartySchema) });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coverImagePath, setCoverImagePath] = useState<string | undefined>();
  const [coverImageFile, setCoverImageFile] = useState<File | undefined>();
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [hasGrandPrize, setHasGrandPrize] = useState<boolean>(false);

  const onChangeDatePicker = (
    nameField: any,
    value: Date | null,
    callback?: (value: Date | null) => void
  ) => {
    setValue(nameField, value);
    if (callback) {
      callback(value);
    }
  };

  const onChangeGrandPrizeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasGrandPrize(event.target.checked);
    setValue("hasGrandPrize", event.target.checked);
  };

  const onChangeStartAt = (value: Date | null) => {
    setEndAt(null);
    setValue("endAt", "");
    setStartAt(value);
    setValue("startAt", moment(value).format("MM/DD/YYYY HH:mm:ss") || "");
  };

  const filterTimePassedEndAt = (time: Date) => {
    if (startAt) {
      const selectedDate = new Date(time);
      return selectedDate > startAt;
    }

    return true;
  };

  const filterDatePassedEndAt = (date: Date) => {
    if (startAt) {
      const selectedDate = new Date(date);
      return (
        moment(selectedDate).format("MM/DD/YYYY") >=
        moment(startAt).format("MM/DD/YYYY")
      );
    }

    return true;
  };

  const onScoreKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    const regex = new RegExp(/\d/);
    const validateCharacters =
      regex.test(key) === false &&
      ["Backspace", "ArrowDown", "ArrowUp"].includes(key) === false;

    if (validateCharacters) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data: WatchParty) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      for (const key in data) {
        const callback =
          watchPartyHandler[key as WatchPartyHandlerKeys] ??
          watchPartyHandler["default"];
        callback(key as never, data, formData);
      }

      await WatchPartyService.createWatchParty(formData);
      ToastMessage("Watch party created successfully.");
      navigate(`/watch-party`);
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("image", coverImageFile);
  }, [coverImageFile]);

  useEffect(() => {
    setValue("hasGrandPrize", hasGrandPrize);
  }, [hasGrandPrize]);

  const onClickBack = () => {
    navigate(`/watch-party`);
  };
  return (
    <ContentLayout
      title="New watch party"
      backButton={true}
      onClickBack={onClickBack}
    >
      <form className="question-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="text"
          error={errors.text}
          className="title-text"
          placeholder="Enter title here"
          register={register}
          registerName="text"
          isInvalid={errors.text !== undefined}
          required={{ required: true }}
        ></InputField>
        <FormGroup title="Media" contentClass="media-content">
          <ImageInput
            id="cover-image"
            label="Cover image"
            setImagePath={setCoverImagePath}
            imagePath={coverImagePath}
            setImageFile={setCoverImageFile}
            imageFile={coverImageFile}
            error={errors.image}
          ></ImageInput>
        </FormGroup>
        <FormGroup title="Attributes">
          <FormControl label="Question's score">
            <InputField
              min={0}
              type="number"
              register={register}
              registerName="score"
              error={errors.score}
              className="score-input"
              onKeyDown={onScoreKeyDown}
              required={{ required: true }}
              placeholder="Enter score here"
              isInvalid={errors.score !== undefined}
            ></InputField>
          </FormControl>
          <FormControl error={errors.startAt} label="Start date">
            <DatePickerField
              value={startAt}
              isInvalid={errors.startAt !== undefined}
              onChange={(e) => onChangeStartAt(e)}
            ></DatePickerField>
          </FormControl>
          <FormControl error={errors.endAt} label="End date">
            <DatePickerField
              value={endAt}
              disable={startAt === null}
              isInvalid={errors.endAt !== undefined}
              filterPassedTime={filterTimePassedEndAt}
              filterPassedDate={filterDatePassedEndAt}
              onChange={(e) => onChangeDatePicker("endAt", e, setEndAt)}
            ></DatePickerField>
          </FormControl>
        </FormGroup>
        <FormGroup title="Stakeholders">
          <WatchPartyStakeholders
            control={control}
            errors={errors}
            register={register}
            setValue={setValue}
          />
        </FormGroup>
        <Checkbox
          id="has-grand-prize"
          name="hasGrandPrize"
          checked={hasGrandPrize}
          onChange={onChangeGrandPrizeCheckbox}
          label="This watch party has a grand prize."
        ></Checkbox>
        {hasGrandPrize && (
          <FormGroup title="Grand Prizes">
            <WatchPartyGrandPrizes
              control={control}
              errors={errors.grandPrizes}
              register={register}
              setValue={setValue}
            />
          </FormGroup>
        )}
        <button
          type="submit"
          disabled={isLoading}
          className={`btn-primary ${isLoading ? "loading" : ""}`}
        >
          Save watch party
        </button>
      </form>
    </ContentLayout>
  );
};
