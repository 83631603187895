import { useState } from "react";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, FormGroup, InputField } from "@/components/Form";

import { useToast } from "@/hooks/useToast";
import { AuthService } from "@/services/AuthService";
import { SignIn, signInHandler, signInSchema } from "../../types";

export const SignInComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignIn>({ resolver: yupResolver(signInSchema) });

  const signIn = useSignIn();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const onSubmit = async (data: SignIn) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      for (const key in data) {
        const callback =
          signInHandler[key as keyof typeof signInHandler] ??
          signInHandler["default"];
        callback(key as never, data, formData);
      }

      const response = await AuthService.signInWithEmailAndPassword(formData);

      if (response.token) {
        signIn({
          token: response.token,
          expiresIn: response.expiresIn ?? 60,
          tokenType: "Bearer",
        });
        navigate("/watch-party");
      }
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup title="Sign in">
        <FormControl label="Email" error={errors.email}>
          <InputField
            type="email"
            register={register}
            registerName="email"
            required={{ required: true }}
            placeholder="Enter your email here"
            isInvalid={errors.email !== undefined}
          ></InputField>
        </FormControl>
        <FormControl label="Password" error={errors.password}>
          <InputField
            type="password"
            register={register}
            registerName="password"
            required={{ required: true }}
            placeholder="Enter your password here"
            isInvalid={errors.password !== undefined}
          ></InputField>
        </FormControl>
        <button
          type="submit"
          disabled={isLoading}
          className={`btn-primary ${isLoading && "loading"}`}
        >
          Send
        </button>
      </FormGroup>
    </form>
  );
};
