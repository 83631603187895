import { SignInComponent } from "../components/signIn/SignInComponent"
import { ContentLayout } from "@/components/Layout";

export const SignIn = () => {
    return <>    
      <ContentLayout
        backButton={false}
        title={"Backoffice - UPTV Watch party"}
      >
        <SignInComponent/>
      </ContentLayout>
    </>;
  };