import React from "react";
import { FieldError } from "react-hook-form";

import "./style.css";

type FormControlProps = {
  label: string;
  error?: FieldError;
  children: React.ReactNode;
};

export const FormControl = ({ label, children, error }: FormControlProps) => {
  return (
    <div className="form-control">
      <label>{label}</label>
      {children}
      {error ? <p className="error-message">{error.message}</p> : <></>}
    </div>
  );
};
