import { useEffect } from "react";
import {
  useFieldArray,
  UseFormRegister,
  Control,
  UseFormSetValue,
  UseFormGetValues,
  FieldErrorsImpl,
} from "react-hook-form";

import { Question } from "../../types/index";

import "./style.css";

type QuestionAlternativesProps = {
  control: Control<Question, any>;
  register: UseFormRegister<Question>;
  setValue: UseFormSetValue<Question>;
  getValues: UseFormGetValues<Question>;
  errors: Partial<FieldErrorsImpl<Question>>;
};

export const QuestionAlternatives = ({
  control,
  register,
  setValue,
  errors,
}: QuestionAlternativesProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "alternatives",
  });

  const addNewAlternative = () => {
    if (fields.length < 4) {
      append({
        text: "",
        isAnswer: false,
        id: String(fields.length + 1),
      });
    }
  };

  const removeAternative = (index: number) => {
    if (fields.length) {
      remove(index);
    }
  };

  const onMouseEnterAlternative = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".alternatives-grid-options");
    options?.classList.add("shown");
  };

  const onMouseLeaveAlternative = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".alternatives-grid-options");
    options?.classList.remove("shown");
  };

  const onIsAnswerClick = (index: number, rowId: string) => {
    const setAnswerRadioValue = (rowId: string, value: boolean) => {
      const row = document.querySelector(`#${rowId}`);
      const rowAnswerRadio: HTMLInputElement | null | undefined =
        row?.querySelector(".answer-radio");

      if (rowAnswerRadio) {
        rowAnswerRadio.checked = value;
      }
    };

    fields.forEach((field, i) => {
      setValue(`alternatives.${i}.isAnswer`, false);
      setAnswerRadioValue(`alternative_${field.id}`, false);
    });

    setAnswerRadioValue(rowId, true);
    setValue(`alternatives.${index}.isAnswer`, true);
  };

  useEffect(() => {
    const dirtyFields = fields.map(({ id, isAnswer }, index) => {
      return isAnswer ? { index, id } : null;
    });
    const finalField = dirtyFields.find((item) => item !== null);
    onIsAnswerClick(
      finalField?.index ?? 0,
      `alternative_${finalField?.id ?? ""}`
    );
  }, [fields]);

  return (
    <div className="alternatives-wrapper">
      <button
        type="button"
        className="btn-outline"
        onClick={addNewAlternative}
        disabled={fields.length < 4 ? false : true}
      >
        Add new answer
      </button>
      <table className="alternatives-grid">
        <thead className="grid-header">
          <tr>
            {fields.length ? (
              <>
                <th>Answer</th>
                <th>Correct</th>
              </>
            ) : (
              <th>Answers</th>
            )}
          </tr>
        </thead>
        <tbody>
          {fields.length ? (
            fields.map((alternative, index) => (
              <tr
                key={alternative.id}
                id={`alternative_${alternative.id}`}
                onMouseEnter={() =>
                  onMouseEnterAlternative(
                    index,
                    `alternative_${alternative.id}`
                  )
                }
                onMouseLeave={() =>
                  onMouseLeaveAlternative(
                    index,
                    `alternative_${alternative.id}`
                  )
                }
              >
                <td>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className={`grid-input ${
                        errors.alternatives && errors.alternatives[index]?.text
                          ? "is-invalid"
                          : ""
                      }`}
                      {...register(`alternatives.${index}.text`, {
                        required: true,
                      })}
                    />
                    {errors.alternatives && errors.alternatives[index]?.text ? (
                      <p className="error-message">
                        {errors.alternatives[index]?.text?.message}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="alternatives-grid-options">
                    <button
                      type="button"
                      className="grid-option-btn delete"
                      onClick={() => removeAternative(index)}
                    >
                      Trash
                    </button>
                  </div>
                </td>
                <td>
                  <input
                    type="radio"
                    className="grid-input answer-radio"
                    onClick={(e) =>
                      onIsAnswerClick(index, `alternative_${alternative.id}`)
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="no-items-found">
              <td>No items found.</td>
            </tr>
          )}
        </tbody>
      </table>
      <p className="error-message">{errors.alternatives?.message}</p>
    </div>
  );
};
