import { axios } from "@/lib/axios";
import { Prize } from "@/features/questions";
import { LuckyAnswer } from "@/features/watchParty";

export type QuestionResponse = {
  alternatives: string[];
  badgeObject: string;
  badgePath: string;
  imagePath: string;
  correctAlternative: string;
  endAt: string;
  id: string;
  prize?: Prize;
  sweepstake: boolean;
  imageObject: string;
  startAt: string;
  text: string;
  hasPrize: boolean;
};

export type OverrideSweepstakeDTO = {
  watchPartyId: string;
  questionId: string;
  userId: string;
};

export class QuestionService {
  static async createQuestion(data: FormData) {
    return axios.post(`watchParties/questions`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static async updateQuestion(data: FormData) {
    return axios.post(`watchParties/questions/edit`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static async deleteQuestion(id: string, watchPartyId: string) {
    return axios.delete(`watchParties/questions`, {
      params: { id, watchPartyId },
    });
  }

  static async getQuestion(id: string, watchPartyId: string) {
    return axios.get(`watchParties/questions/show`, {
      params: { id, watchPartyId },
    }) as Promise<QuestionResponse>;
  }

  static async getQuestions(watchPartyId: string) {
    return axios.get(`watchParties/questions`, {
      params: { watchPartyId },
    }) as Promise<{ [key: string]: QuestionResponse }>;
  }

  static async importQuestions(watchPartyId: string, data: FormData) {
    return axios.post(
      `watchParties/questions/import?watchPartyId=${watchPartyId}`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  static async getLuckyAnswers(watchPartyId: string, questionId: string) {
    return axios.get(
      `watchParties/questions/answers/luckyAnswer?watchPartyId=${watchPartyId}&questionId=${questionId}`
    ) as Promise<LuckyAnswer>;
  }

  static async overrideSweepstake(data: OverrideSweepstakeDTO) {
    return axios.post(
      `watchParties/questions/answers/overrideSweepstake`,
      data
    );
  }

  static async exportAnswersByQuestion({
    watchPartyId,
    questionId,
    userId,
  }: OverrideSweepstakeDTO) {
    return axios.get(
      `watchParties/questions/answers/export?watchPartyId=${watchPartyId}&questionId=${questionId}&userId=${userId}`,
      { responseType: "blob" }
    ) as Promise<Blob>;
  }
}
