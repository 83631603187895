import toast from "react-hot-toast";

import { Toast } from "@/components/Elements/Toast/Toast";

export const useToast = (message: string) => {
  toast((t) => <Toast t={t} message={message} />, {
    style: {
      borderRadius: "3px",
    },
  });
};
