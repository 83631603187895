import moment from "moment";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@/hooks/useToast";
import { sortByDate } from "@/utils/sortByDate";
import { QuestionService } from "@/services/QuestionService";
import { WatchPartyService } from "@/services/WatchPartyService";
import { transformURLToObject } from "@/utils/transformURLtoFile";
import { ContentLayout } from "@/components/Layout/ContentLayout/ContentLayout";
import {
  WatchParty,
  watchPartyHandler,
  WatchPartyHandlerKeys,
  WatchPartyKeys,
  watchPartySchema,
} from "@/features/watchParty";
import {
  WatchPartyGrandPrizes,
  WatchPartyQuestions,
  WatchPartyStakeholders,
} from "../components";
import {
  Checkbox,
  FormGroup,
  InputField,
  ImageInput,
  FormControl,
  DatePickerField,
} from "@/components/Form";

export const EditWatchParty = () => {
  const navigate = useNavigate();
  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<WatchParty>({ resolver: yupResolver(watchPartySchema) });

  const { id: watchPartyId } = useParams();
  const [watchParty, setWatchParty] = useState<WatchParty>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coverImagePath, setCoverImagePath] = useState<string | undefined>();
  const [coverImageFile, setCoverImageFile] = useState<File | undefined>();
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [hasGrandPrize, setHasGrandPrize] = useState<boolean>(false);

  const onChangeGrandPrizeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasGrandPrize(event.target.checked);
    setValue("hasGrandPrize", event.target.checked);
  };

  const onChangeDatePicker = (
    nameField: any,
    value: Date | null,
    callback?: (value: Date | null) => void
  ) => {
    setValue(nameField, value);
    if (callback) {
      callback(value);
    }
  };

  const onChangeStartAt = (value: Date | null) => {
    setEndAt(null);
    setValue("endAt", "");
    setStartAt(value);
    setValue("startAt", moment(value).format("MM/DD/YYYY HH:mm:ss") || "");
  };

  const filterTimePassedEndAt = (time: Date) => {
    if (startAt) {
      const selectedDate = new Date(time);
      return selectedDate > startAt;
    }

    return true;
  };

  const filterDatePassedEndAt = (date: Date) => {
    if (startAt) {
      const selectedDate = new Date(date);
      return (
        moment(selectedDate).format("MM/DD/YYYY") >=
        moment(startAt).format("MM/DD/YYYY")
      );
    }

    return true;
  };

  const onScoreKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    const regex = new RegExp(/\d/);
    const validateCharacters =
      regex.test(key) === false &&
      ["Backspace", "ArrowDown", "ArrowUp"].includes(key) === false;

    if (validateCharacters) {
      event.preventDefault();
    }
  };

  const onClickAddNew = () => {
    navigate(`/watch-party/create`);
  };
  const onClickBack = () => {
    navigate(`/watch-party`);
  };

  const onDeletedQuestion = (id: string) => {
    if (watchParty) {
      const othersQuestions =
        watchParty.questions.filter((question) => question.id !== id) ?? [];
      setWatchParty({
        ...watchParty,
        questions: othersQuestions,
      });
    }
  };

  const loadWatchParty = async (id: string) => {
    const response = await WatchPartyService.getWatchParty(id);

    response.startAt = moment(new Date(Number(response.startAt))).format(
      "MM/DD/YYYY HH:mm:ss"
    );
    response.endAt = moment(new Date(Number(response.endAt))).format(
      "MM/DD/YYYY HH:mm:ss"
    );

    let questionsArray = Object.values(response.questions ?? []);
    questionsArray = questionsArray
      .sort((a, b) =>
        sortByDate(new Date(Number(a.startAt)), new Date(Number(b.startAt)))
      )
      .map((question) => {
        const startAt = moment(new Date(Number(question.startAt))).format(
          "MM/DD/YYYY HH:mm:ss"
        );
        const endAt = moment(new Date(Number(question.endAt))).format(
          "MM/DD/YYYY HH:mm:ss"
        );

        return { ...question, startAt, endAt };
      });

    const stakeholdersEmails =
      response.stakeholdersEmails?.map((item: string, index) => ({
        email: item,
        id: String(index + 1),
      })) ?? [];

    response.questions = questionsArray;

    setWatchParty({ ...response, stakeholdersEmails });
    setStartAt(new Date(response.startAt));
    setEndAt(new Date(response.endAt));
    setHasGrandPrize(response.grandPrizes !== undefined);
    setValue("hasGrandPrize", response.grandPrizes !== undefined);
    setCoverImagePath(response.imagePath);
    const imageFile = await transformURLToObject(response.imagePath);
    setCoverImageFile(imageFile);
  };

  const onSubmit = async (data: WatchParty) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      for (const key in data) {
        const callback =
          watchPartyHandler[key as WatchPartyHandlerKeys] ??
          watchPartyHandler["default"];
        callback(key as never, data, formData);
      }

      await WatchPartyService.updateWatchParty(formData);
      ToastMessage("Watch party updated successfully.");
      navigate(`/watch-party`);
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const reloadQuestions = async (watchPartyId: string) => {
    try {
      const response = await QuestionService.getQuestions(watchPartyId);

      const questionsArray = Object.values(response ?? [])
        .sort((a, b) =>
          sortByDate(new Date(Number(a.startAt)), new Date(Number(b.startAt)))
        )
        .map((question) => {
          const startAt = moment(new Date(Number(question.startAt))).format(
            "MM/DD/YYYY HH:mm:ss"
          );
          const endAt = moment(new Date(Number(question.endAt))).format(
            "MM/DD/YYYY HH:mm:ss"
          );

          const finalAlternatives = question.alternatives.map(
            (alternative, index) => {
              return {
                id: String(index + 1),
                text: alternative,
                isAnswer: index === Number(question.correctAlternative),
              };
            }
          );

          return {
            ...question,
            startAt,
            endAt,
            alternatives: finalAlternatives,
          };
        });

      // @ts-ignore
      setWatchParty({ ...watchParty, questions: questionsArray });
    } catch (err) {
      ToastMessage("Error on loading questions.");
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("image", coverImageFile);
  }, [coverImageFile]);

  useEffect(() => {
    loadWatchParty(watchPartyId ?? "");
  }, [watchPartyId]);

  useEffect(() => {
    for (const key in watchParty) {
      setValue(key as WatchPartyKeys, watchParty[key as WatchPartyKeys]);
    }
  }, [watchParty]);

  return (
    <ContentLayout
      addNewButton={true}
      backButton={true}
      onClickBack={onClickBack}
      onClickAddNew={onClickAddNew}
      title="Edit watch party"
    >
      {watchParty ? (
        <form className="question-form" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type="text"
            className="title-text"
            placeholder="Enter title here"
            error={errors.text}
            register={register}
            registerName="text"
            isInvalid={errors.text !== undefined}
            required={{ required: true }}
          ></InputField>
          <FormGroup title="Media" contentClass="media-content">
            <ImageInput
              id="cover-image"
              label="Cover image"
              setImagePath={setCoverImagePath}
              imagePath={coverImagePath}
              setImageFile={setCoverImageFile}
              imageFile={coverImageFile}
              error={errors.imagePath}
            ></ImageInput>
          </FormGroup>
          <FormGroup title="Attributes">
            <FormControl label="Question's score">
              <InputField
                min={0}
                type="number"
                register={register}
                registerName="score"
                error={errors.score}
                className="score-input"
                onKeyDown={onScoreKeyDown}
                required={{ required: true }}
                placeholder="Enter score here"
                isInvalid={errors.score !== undefined}
              ></InputField>
            </FormControl>
            <FormControl error={errors.startAt} label="Start date">
              <DatePickerField
                value={startAt}
                isInvalid={errors.startAt !== undefined}
                onChange={(e) => onChangeStartAt(e)}
              ></DatePickerField>
            </FormControl>
            <FormControl error={errors.endAt} label="End date">
              <DatePickerField
                value={endAt}
                disable={startAt === null}
                isInvalid={errors.endAt !== undefined}
                filterPassedTime={filterTimePassedEndAt}
                filterPassedDate={filterDatePassedEndAt}
                onChange={(e) => onChangeDatePicker("endAt", e, setEndAt)}
              ></DatePickerField>
            </FormControl>
          </FormGroup>
          <FormGroup title="Stakeholders">
            <WatchPartyStakeholders
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
            />
          </FormGroup>
          <Checkbox
            id="has-grand-prize"
            name="hasGrandPrize"
            checked={hasGrandPrize}
            onChange={onChangeGrandPrizeCheckbox}
            label="This watch party has a grand prize."
          ></Checkbox>
          {hasGrandPrize && (
            <FormGroup title="Grand Prizes">
              <WatchPartyGrandPrizes
                control={control}
                errors={errors.grandPrizes}
                register={register}
                setValue={setValue}
              />
            </FormGroup>
          )}
          <FormGroup title="Questions">
            <WatchPartyQuestions
              watchParty={watchParty}
              ToastMessage={ToastMessage}
              updateQuestions={reloadQuestions}
              onDeleteQuestion={onDeletedQuestion}
              questions={watchParty?.questions ?? []}
              watchPartyId={watchPartyId ?? ""}
            ></WatchPartyQuestions>
          </FormGroup>
          <button
            type="submit"
            disabled={isLoading}
            className={`btn-primary ${isLoading ? "loading" : ""}`}
          >
            Save watch party
          </button>
        </form>
      ) : (
        <></>
      )}
    </ContentLayout>
  );
};
