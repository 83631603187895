import * as yup from "yup";

export type SignIn = {
  email: string;
  password: string;
};

export const signInHandler = {
  default: (key: keyof SignIn, signIn: SignIn, formData: FormData) => {
    formData.append(key, signIn[key] as string);
  },
} as const;

export const signInSchema = yup
  .object({
    email: yup.string().trim().required("The email field is required."),
    password: yup.string().required("The password field is required."),
  }); 
