import React from "react";

import "./style.css";

type FormGroupProps = {
  title: string;
  children: React.ReactNode;
  contentClass?: string;
};

export const FormGroup = ({
  title,
  children,
  contentClass,
}: FormGroupProps) => {
  return (
    <div className="form-group">
      <div className="group-header">
        <h2>{title}</h2>
      </div>
      <div className={`group-content ${contentClass}`}>{children}</div>
    </div>
  );
};
