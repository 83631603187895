import {
  useFieldArray,
  UseFormRegister,
  Control,
  UseFormSetValue,
  FieldErrorsImpl,
  Merge,
  FieldError,
} from "react-hook-form";

import { CurrencyInput } from "@/components/Form";
import { WatchParty, GrandPrize } from "@/features/watchParty";

import "./style.css";

type WatchPartyGrandPrizesProps = {
  control: Control<WatchParty, any>;
  register: UseFormRegister<WatchParty>;
  setValue: UseFormSetValue<WatchParty>;
  errors:
    | Merge<
        FieldError,
        (Merge<FieldError, FieldErrorsImpl<GrandPrize>> | undefined)[]
      >
    | undefined;
};

export const WatchPartyGrandPrizes = ({
  control,
  register,
  setValue,
  errors,
}: WatchPartyGrandPrizesProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "grandPrizes",
  });

  const addNewGrandPrize = () => {
    append({
      name: "",
      description: "",
      price: "",
      id: String(fields.length + 1),
    });
  };

  const removeGrandPrize = (index: number) => {
    if (fields.length) {
      remove(index);
    }
  };

  const onMouseEnterGrandPrize = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grandprizes-grid-options");
    options?.classList.add("shown");
  };

  const onMouseLeaveGrandPrize = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grandprizes-grid-options");
    options?.classList.remove("shown");
  };

  const onChangeGrandPrizePrice = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(`grandPrizes.${index}.price`, event.target.value);
  };

  return (
    <div className="grandprizes-wrapper">
      <button type="button" className="btn-outline" onClick={addNewGrandPrize}>
        Add new Grand Prize
      </button>
      <table className="grandprizes-grid">
        <thead className="grid-header">
          <tr>
            {fields.length ? (
              <>
                <th>Name</th>
                <th>Description</th>
                <th>Prize</th>
              </>
            ) : (
              <th>Grand Prizes</th>
            )}
          </tr>
        </thead>
        <tbody>
          {fields.length ? (
            fields.map((grandPrize, index) => (
              <tr
                key={grandPrize.id}
                id={`grandprizes_${grandPrize.id}`}
                onMouseEnter={() =>
                  onMouseEnterGrandPrize(index, `grandprizes_${grandPrize.id}`)
                }
                onMouseLeave={() =>
                  onMouseLeaveGrandPrize(index, `grandprizes_${grandPrize.id}`)
                }
              >
                <td>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder="Enter name here"
                      className={`grid-input ${
                        errors && errors[index]?.name ? "is-invalid" : ""
                      }`}
                      {...register(`grandPrizes.${index}.name`, {
                        required: true,
                      })}
                    />
                    {!!(errors && errors[index]?.name) && (
                      <p className="error-message">
                        {errors[index]?.name?.message}
                      </p>
                    )}
                  </div>
                  <div className="grandprizes-grid-options">
                    <button
                      type="button"
                      className="grid-option-btn delete"
                      onClick={() => removeGrandPrize(index)}
                    >
                      Trash
                    </button>
                  </div>
                </td>
                <td>
                  <div className="input-wrapper">
                    <textarea
                      placeholder="Enter description here"
                      className={`grid-input ${
                        errors && errors[index]?.description ? "is-invalid" : ""
                      }`}
                      {...register(`grandPrizes.${index}.description`, {
                        required: true,
                      })}
                    ></textarea>
                    {errors && errors[index]?.description && (
                      <p className="error-message">
                        {errors[index]?.description?.message}
                      </p>
                    )}
                  </div>
                </td>
                <td>
                  <div className="input-wrapper">
                    <CurrencyInput
                      register={register}
                      value={grandPrize.price}
                      required={{ required: true }}
                      placeholder="Enter prize here"
                      registerName={`grandPrizes.${index}.price`}
                      onChange={(e) => onChangeGrandPrizePrice(index, e)}
                      className={`grid-input ${
                        errors && errors[index]?.price ? "is-invalid" : ""
                      }`}
                    ></CurrencyInput>
                    {errors && errors[index]?.price && (
                      <p className="error-message">
                        {errors[index]?.price?.message}
                      </p>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr className="no-items-found">
              <td colSpan={3}>No items found.</td>
            </tr>
          )}
        </tbody>
      </table>
      <p className="error-message">{errors?.message}</p>
    </div>
  );
};
