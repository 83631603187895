import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface DatePickerFieldProps {
  onChange: (e: Date | null) => void;
  filterPassedTime?: (time: Date) => boolean;
  filterPassedDate?: (date: Date) => boolean;
  value: Date | null;
  disable?: boolean;
  excludeTimes?: Date[];
  isInvalid?: boolean;
}

export const DatePickerField = ({
  onChange,
  filterPassedTime,
  filterPassedDate,
  value,
  excludeTimes,
  disable,
  isInvalid = false,
}: DatePickerFieldProps) => {
  const onChangeRaw = (event: any) => {
    if (event.type === "click") {
      return;
    }

    const input = event.target;
    const date = input.value;

    const validateDelete = [
      "deleteContentForward",
      "deleteContentBackward",
    ].includes(event.nativeEvent.inputType);

    if (date.match(/^\d{2}$/) !== null && validateDelete === false) {
      input.value = date + "/";
    }
    if (date.match(/^\d{2}\/\d{2}$/) !== null && validateDelete === false) {
      input.value = date + "/";
    }
  };

  return (
    <DatePicker
      showTimeSelect
      dateFormat="Pp"
      selected={value}
      disabled={disable}
      timeIntervals={10}
      excludeTimes={excludeTimes}
      onChangeRaw={onChangeRaw}
      onChange={(e) => onChange(e)}
      filterTime={filterPassedTime}
      filterDate={filterPassedDate}
      className={isInvalid ? "is-invalid" : ""}
    />
  );
};
