import * as yup from "yup";

import { BaseEntity } from "@/types";

export type Question = {
  text: string;
  imagePath: string;
  image?: File;
  badge?: File;
  badgePath: string;
  startAt: string;
  endAt: string;
  hasPrize: boolean;
  prize: Prize;
  sweepstake: boolean;
  alternatives: Alternative[];
} & BaseEntity;

export type Alternative = {
  text: string;
  isAnswer: boolean;
} & BaseEntity;

export type Prize = {
  name: string;
  description: string;
} & BaseEntity;

export const questionHandler = {
  startAt: (key: "startAt", question: Question, formData: FormData) => {
    const timestamp = isNaN(Number(question[key]))
      ? new Date(question[key] as string).getTime()
      : question[key];
    formData.append(key, String(timestamp));
  },
  endAt: (key: "endAt", question: Question, formData: FormData) => {
    const timestamp = isNaN(Number(question[key]))
      ? new Date(question[key]).getTime()
      : question[key];
    formData.append(key, String(timestamp));
  },
  alternatives: (
    key: "alternatives",
    question: Question,
    formData: FormData
  ) => {
    question[key].forEach((item: Alternative, index: number) => {
      formData.append(`${key}[${index}]`, item.text);
      if (item.isAnswer) {
        formData.append("correctAlternative", String(index));
      }
    });
  },
  prize: (key: "prize", question: Question, formData: FormData) => {
    if (question.hasPrize === false) {
      return;
    }
    const prize = question[key];
    for (const prizeField in prize) {
      formData.append(`prize[${prizeField}]`, prize[prizeField as keyof Prize]);
    }
  },
  default: (key: keyof Question, question: Question, formData: FormData) => {
    if (["image", "badge"].includes(key) && question[key] === undefined) {
      return;
    }
    formData.append(key, question[key] as string);
  },
} as const;

export const questionSchema = yup
  .object({
    text: yup.string().trim().required("The question text is required."),
    image: yup.mixed().notRequired(),
    badge: yup.mixed().notRequired(),
    startAt: yup
      .string()
      .trim()
      .required("The question start time is required."),
    endAt: yup.string().trim().required("The question end time is required."),
    hasPrize: yup.boolean().required(),
    prize: yup
      .object({
        name: yup.string(),
        description: yup.string(),
      })
      .when("hasPrize", {
        is: true,
        then: yup.object({
          name: yup.string().trim().required("The prize name is required."),
          description: yup
            .string()
            .trim()
            .required("The prize description is required."),
        }),
      }),
    alternatives: yup
      .array(
        yup.object({
          text: yup
            .string()
            .trim()
            .required("The alternative text is required."),
          isAnswer: yup
            .boolean()
            .required("The question response is required."),
        })
      )
      .required("The alternatives are required."),
  })
  .required();
