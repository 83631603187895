import { USER_EMAIL, USER_PASSWORD } from "@/config";

import { axios } from "@/lib/axios";
import storage from "@/utils/storage";

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export type UserResponse = {
  token: string;
  expiresIn: number;
};

export type UserData = {
  email: string;
  password: string;
};

export class AuthService {
  async loginFn() {
    const userData = this.getUserData();
    const response = await this.loginWithEmailAndPassword(userData);
    const user = await this.handleUserResponse(response);
    return user;
  }

  private async loginWithEmailAndPassword(
    data: LoginCredentialsDTO
  ): Promise<UserResponse> {
    return axios.post("login", data);
  }

  public static async signInWithEmailAndPassword(
    data: FormData
  ): Promise<UserResponse>{
    return axios.post("login", data).then();
  }

  private async handleUserResponse(data: UserResponse) {
    const { token } = data;
    storage.setToken(token);
    return data;
  }

  private getUserData(): UserData {
    return {
      email: USER_EMAIL,
      password: USER_PASSWORD,
    };
  }
}
