import { axios } from "@/lib/axios";
import { WatchParty } from "@/features/watchParty";
import {OverrideSweepstakeDTO} from "@/services/QuestionService";

type WatchPartyResponse = {
  stakeholdersEmails: string[];
} & WatchParty;

export class WatchPartyService {
  static async listWatchParties() {
    return axios.get(`watchParties`) as Promise<{ [key: string]: WatchParty }>;
  }

  static async createWatchParty(data: FormData) {
    return axios.post(`watchParties`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static async deleteWatchParty(id: string) {
    return axios.delete(`watchParties`, {
      params: { id },
    }) as Promise<WatchParty>;
  }

  static async getWatchParty(id: string) {
    return axios.get(`watchParties/show`, {
      params: { id },
    }) as Promise<WatchPartyResponse>;
  }

  static async updateWatchParty(data: FormData) {
    return axios.post(`watchParties/edit`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static async publishWatchParty(data: { id: string; publish: boolean }) {
    return axios.post(`watchParties/publish`, data);
  }

  static async exportUsersRanking({ watchPartyId }: { watchPartyId: string }) {
    return axios.get(
        `watchParties/exportLeaderboardScores?id=${watchPartyId}`,
        { responseType: "blob" }
    ) as Promise<Blob>;
  }
}
