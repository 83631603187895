/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useToast } from "@/hooks/useToast";
import { sortByDate } from "@/utils/sortByDate";
import { QuestionAlternatives } from "../components";
import { WatchParty } from "@/features/watchParty/types";
import { QuestionService } from "@/services/QuestionService";
import { WatchPartyService } from "@/services/WatchPartyService";
import { Question, questionHandler, questionSchema } from "../types/index";
import { ContentLayout } from "@/components/Layout/ContentLayout/ContentLayout";
import {
  DatePickerField,
  FormGroup,
  ImageInput,
  InputField,
  FormControl,
  Checkbox,
} from "@/components/Form";

import "../assets/questions.css";

export const CreateQuestion = () => {
  const navigate = useNavigate();
  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Question>({ resolver: yupResolver(questionSchema) });

  const location = useLocation();

  const { id: watchPartyId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coverImagePath, setCoverImagePath] = useState<string | undefined>();
  const [coverImageFile, setCoverImageFile] = useState<File | undefined>();
  const [badgePath, setBadgePath] = useState<string | undefined>();
  const [badgeFile, setBadgeFile] = useState<File | undefined>();
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [hasPrize, setHasPrize] = useState<boolean>(false);
  const [availableTimespan, setAvailableTimespan] = useState<{
    min?: Date;
    max?: Date;
    excludeTimes?: Date[];
    currentExcludeTimes?: Date[];
  }>();

  const onChangePrizeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasPrize(event.target.checked);
    setValue("hasPrize", event.target.checked);
  };

  const onChangeDatePicker = (
    nameField: any,
    value: Date | null,
    callback?: (value: Date | null) => void
  ) => {
    const valueTimestamp = value?.getTime() ?? 1000;
    value = new Date(valueTimestamp - 1000);
    const newExcludeTimes = availableTimespan?.excludeTimes?.filter(
      (time) =>
        moment(time).format("MM/DD/YYYY") === moment(value).format("MM/DD/YYYY")
    );

    setAvailableTimespan({
      ...availableTimespan,
      currentExcludeTimes: newExcludeTimes,
    });
    setValue(nameField, value);
    if (callback) {
      callback(value);
    }
  };

  const onChangeStartAt = (value: Date | null) => {
    const newExcludeTimes = availableTimespan?.excludeTimes?.filter(
      (time) =>
        moment(time).format("MM/DD/YYYY") === moment(value).format("MM/DD/YYYY")
    );

    setAvailableTimespan({
      ...availableTimespan,
      currentExcludeTimes: newExcludeTimes,
    });
    setEndAt(null);
    setValue("endAt", "");
    setStartAt(value);
    setValue("startAt", moment(value).format("MM/DD/YYYY HH:mm:ss") || "");
  };

  const filterTimePassed = (time: Date) => {
    if (availableTimespan?.min && availableTimespan.max) {
      const selectedDate = new Date(time);
      const minDate = new Date(availableTimespan?.min);
      const maxDate = new Date(availableTimespan?.max);

      return selectedDate >= minDate && selectedDate <= maxDate;
    }

    return true;
  };

  const filterTimePassedEndAt = (time: Date) => {
    if (availableTimespan?.min && availableTimespan.max && startAt) {
      const selectedDate = new Date(time);

      const timesGreaterThanStartAt =
        availableTimespan?.excludeTimes?.filter((time) => time > startAt) ?? [];

      const minDate = new Date(availableTimespan?.min);
      const maxDate =
        timesGreaterThanStartAt[0] ?? new Date(availableTimespan?.max);

      return (
        selectedDate >= minDate &&
        selectedDate > startAt &&
        selectedDate <= maxDate
      );
    }

    return true;
  };

  const filterDatePassed = (date: Date) => {
    if (availableTimespan?.min && availableTimespan.max) {
      const selectedDate = new Date(date);
      const minDate = new Date(
        moment(availableTimespan?.min).format("MM/DD/YYYY")
      );
      const maxDate = new Date(
        moment(availableTimespan?.max).format("MM/DD/YYYY")
      );

      return selectedDate >= minDate && selectedDate <= maxDate;
    }

    return true;
  };

  const filterDatePassedEndAt = (date: Date) => {
    if (availableTimespan?.min && availableTimespan.max && startAt) {
      const selectedDate = new Date(date);

      const timesGreaterThanStartAt =
        availableTimespan?.excludeTimes?.filter((time) => time > startAt) ?? [];

      const minDate = new Date(
        moment(availableTimespan?.min).format("MM/DD/YYYY")
      );
      const maxDate = new Date(
        moment(
          timesGreaterThanStartAt[0] ?? new Date(availableTimespan?.max)
        ).format("MM/DD/YYYY")
      );

      return selectedDate >= minDate && selectedDate <= maxDate;
    }

    return true;
  };

  const filterAvailableTimespan = (watchParty: WatchParty) => {
    let times = [
      ...watchParty.questions.map((question) => {
        const getBetweenDates = (startAt: Date, endAt: Date) => {
          let returnArray = [];
          let currentDate = startAt;

          while (currentDate <= endAt) {
            returnArray.push(
              new Date(moment(currentDate).format("MM/DD/YYYY HH:mm:ss"))
            );
            currentDate = new Date(
              moment(currentDate)
                .add(10, "minutes")
                .format("MM/DD/YYYY HH:mm:ss")
            );
          }

          return returnArray;
        };

        return getBetweenDates(
          new Date(question.startAt),
          new Date(question.endAt)
        );
      }),
    ]
      .flat()
      .sort(sortByDate);

    return {
      min: new Date(watchParty.startAt),
      max: new Date(watchParty.endAt),
      currentExcludeTimes: times,
      excludeTimes: times,
    };
  };

  const getWatchParty = async (watchPartyId: string) => {
    const response = await WatchPartyService.getWatchParty(watchPartyId);
    response.startAt = moment(new Date(Number(response.startAt))).format(
      "MM/DD/YYYY HH:mm:ss"
    );
    response.endAt = moment(new Date(Number(response.endAt))).format(
      "MM/DD/YYYY HH:mm:ss"
    );

    let questionsArray = Object.values(response.questions ?? []);
    questionsArray = questionsArray.map((question) => {
      const startAt = moment(new Date(Number(question.startAt))).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const endAt = moment(new Date(Number(question.endAt))).format(
        "MM/DD/YYYY HH:mm:ss"
      );

      return { ...question, startAt, endAt };
    });

    response.questions = questionsArray;

    return response;
  };

  const loadAvailableTimespan = async (location: Location) => {
    let watchParty = location.state?.watchParty ?? null;

    if (!watchParty) {
      watchParty = await getWatchParty(watchPartyId ?? "");
    }

    const availableTimespan = filterAvailableTimespan(watchParty);

    setAvailableTimespan(availableTimespan);
  };

  const onSubmit = async (data: Question) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      for (const key in data) {
        const callback =
          questionHandler[key as keyof typeof questionHandler] ??
          questionHandler["default"];
        callback(key as never, data, formData);
      }

      if (watchPartyId) {
        formData.append("watchPartyId", watchPartyId);
      }

      await QuestionService.createQuestion(formData);
      ToastMessage("Question created successfully.");
      navigate(`/watch-party/${watchPartyId}/edit`);
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("hasPrize", hasPrize);
  }, [hasPrize]);

  useEffect(() => {
    setValue("image", coverImageFile);
  }, [coverImageFile]);

  useEffect(() => {
    setValue("badge", badgeFile);
  }, [badgeFile]);

  useEffect(() => {
    loadAvailableTimespan(location);
  }, [location]);

  return (
    <ContentLayout title="New question">
      <form className="question-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="text"
          error={errors.text}
          className="title-text"
          placeholder="Enter question here"
          register={register}
          registerName="text"
          isInvalid={errors.text !== undefined}
          required={{ required: true }}
        ></InputField>
        <FormGroup title="Media" contentClass="media-content">
          <ImageInput
            id="cover-image"
            label="Cover image"
            setImagePath={setCoverImagePath}
            imagePath={coverImagePath}
            setImageFile={setCoverImageFile}
            imageFile={coverImageFile}
            error={errors.imagePath}
          ></ImageInput>
          <ImageInput
            id="badge-image"
            label="Badge image"
            setImagePath={setBadgePath}
            imagePath={badgePath}
            setImageFile={setBadgeFile}
            imageFile={badgeFile}
          ></ImageInput>
        </FormGroup>
        <FormGroup title="Timespan">
          <FormControl error={errors.startAt} label="Start date">
            <DatePickerField
              value={startAt}
              filterPassedTime={filterTimePassed}
              filterPassedDate={filterDatePassed}
              isInvalid={errors.startAt !== undefined}
              excludeTimes={availableTimespan?.currentExcludeTimes}
              onChange={(e) => onChangeStartAt(e)}
            ></DatePickerField>
          </FormControl>
          <FormControl error={errors.endAt} label="End date">
            <DatePickerField
              value={endAt}
              disable={startAt === null}
              filterPassedTime={filterTimePassedEndAt}
              filterPassedDate={filterDatePassedEndAt}
              isInvalid={errors.endAt !== undefined}
              excludeTimes={availableTimespan?.currentExcludeTimes}
              onChange={(e) => onChangeDatePicker("endAt", e, setEndAt)}
            />
          </FormControl>
        </FormGroup>
        <Checkbox
          id="has-prize"
          name="hasPrize"
          checked={hasPrize}
          onChange={onChangePrizeCheckbox}
          label="This question has a prize."
        ></Checkbox>
        {hasPrize && (
          <FormGroup title="Prize">
            <FormControl label="Name" error={errors.prize?.name}>
              <InputField
                type="text"
                register={register}
                registerName="prize.name"
                required={{ required: true }}
                placeholder="Enter prize name here"
                isInvalid={errors.prize?.name !== undefined}
              ></InputField>
            </FormControl>
            <FormControl label="Description" error={errors.prize?.description}>
              <InputField
                type="text"
                register={register}
                registerName="prize.description"
                required={{ required: true }}
                placeholder="Enter prize description here"
                isInvalid={errors.prize?.description !== undefined}
              ></InputField>
            </FormControl>
          </FormGroup>
        )}
        <FormGroup title="Answers">
          <QuestionAlternatives
            setValue={setValue}
            control={control}
            register={register}
            getValues={getValues}
            errors={errors}
          ></QuestionAlternatives>
        </FormGroup>
        <button
          type="submit"
          disabled={isLoading}
          className={`btn-primary ${isLoading ? "loading" : ""}`}
        >
          Save Question
        </button>
      </form>
    </ContentLayout>
  );
};
