import { useRef, useState } from "react";

import "./style.css";

type QuestionsUploadParams = {
  ToastMessage: (text: string) => void;
  setQuestionsFile: (file?: File) => void;
  id: string;
  questionsFile?: File;
  isLoadingUpload: boolean;
};

export const QuestionsUpload = ({
  ToastMessage,
  setQuestionsFile,
  id,
  questionsFile,
  isLoadingUpload,
}: QuestionsUploadParams) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string | undefined>();

  const cancelUpload = () => {
    setFileName(undefined);
    setQuestionsFile(undefined);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length < 1) {
      return;
    }

    const FILE: File = event.target.files[0];

    try {
      if (FILE.type !== "text/csv") {
        ToastMessage("This file extension is not allowed.");
        return;
      }

      setQuestionsFile(FILE);
      setFileName(FILE.name);
    } catch (err) {
      ToastMessage("An error ocurred.");
    }
  };

  return (
    <div className="upload-questions-wrapper">
      <label className="file-label" htmlFor={id}>
        <div
          className={`btn-outline upload-btn ${
            questionsFile || fileName ? "selected" : ""
          }`}
        >
          Select file
        </div>
      </label>
      {questionsFile || fileName ? (
        <>
          <div className="file-name">{fileName}</div>
          <button
            type="button"
            onClick={cancelUpload}
            disabled={isLoadingUpload}
            className="btn-outline cancel"
          >
            Cancel upload
          </button>
        </>
      ) : (
        <></>
      )}
      <input
        id={id}
        type="file"
        ref={inputRef}
        accept="text/csv"
        onChange={onChangeFile}
        style={{ display: "none" }}
      />
    </div>
  );
};
