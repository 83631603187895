import { FieldError } from "react-hook-form";

import { useToast } from "@/hooks/useToast";

import "./styles.css";

type ImageInputProps = {
  setImagePath: (path?: string) => void;
  setImageFile: (file?: File) => void;
  imageFile?: File;
  id: string;
  label: string;
  imagePath?: string;
  error?: FieldError;
};

export const ImageInput = ({
  setImagePath,
  setImageFile,
  label,
  id,
  imagePath,
  imageFile,
  error,
}: ImageInputProps) => {
  const MAX_SIZE_FIVE_MEGABYTES = 5 * 1024 * 1024;

  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const cancelUpload = () => {
    setImagePath(undefined);
    setImageFile(undefined);
  };

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length < 1) {
      return;
    }

    const FILE: File = event.target.files[0];

    try {
      if (["image/png", "image/jpeg"].includes(FILE.type) === false) {
        ToastMessage("This file extension is not allowed.");
        return;
      }

      setImageFile(FILE);
      const fileSize = FILE.size;

      if (fileSize > MAX_SIZE_FIVE_MEGABYTES) {
        ToastMessage("File size not allowed, maximum size is 5Mb.");
        setImageFile(undefined);
      }
    } catch (err) {
      ToastMessage("An error ocurred.");
    }
  };

  return (
    <div className={`image-upload-wrapper ${error ? "is-invalid" : ""}`}>
      <label className="control-name">{label}</label>
      <label className="file-label" htmlFor={id}>
        <div
          className={`btn-outline upload-btn ${
            imagePath || imageFile ? "selected" : ""
          }`}
        >
          Select file
        </div>
      </label>
      {imageFile || imagePath ? (
        <>
          <button
            type="button"
            onClick={cancelUpload}
            className="btn-outline cancel"
          >
            Cancel upload
          </button>
          <p>Image preview:</p>
        </>
      ) : (
        <p className="maximum-size">Maximum upload file size: 5Mb.</p>
      )}
      <div
        className={`preview-image ${imageFile || imagePath ? "selected" : ""}`}
        style={{
          backgroundImage: `url("${
            imageFile ? URL.createObjectURL(imageFile) : imagePath
          }")`,
        }}
      ></div>
      <input
        id={id}
        type="file"
        onChange={onChangeFile}
        style={{ display: "none" }}
        accept="image/png, image/gif, image/jpeg"
      />
      <p className="error-message">{error?.message}</p>
    </div>
  );
};
