import moment from "moment";
import { useState, useEffect } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useToast } from "@/hooks/useToast";
import { FormGroup } from "@/components/Form";
import { Question } from "@/features/questions";
import { WatchParty } from "@/features/watchParty";
import { ContentLayout } from "@/components/Layout";
import { WatchPartyService } from "@/services/WatchPartyService";
import { DetailQuestionsList } from "@/features/watchParty/components";

export const WatchPartyDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: watchPartyId } = useParams();

  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [watchParty, setWatchParty] = useState<WatchParty | undefined>();
  const [questions, setQuestions] = useState<Question[] | undefined>();

  const onClickBack = () => {
    navigate(`/watch-party`);
  };

  const getWatchParty = async (watchPartyId: string) => {
    const response = await WatchPartyService.getWatchParty(watchPartyId);
    return response;
  };

  const loadSweepstakes = async () => {
    try {
      setIsLoading(true);
      const questions = watchParty?.questions ?? [];
      setQuestions(questions);
    } catch (err) {
      console.error(err);
      ToastMessage("Error on loading the sweepstakes.");
    } finally {
      setIsLoading(false);
    }
  };

  const loadWatchParty = async (location: Location) => {
    let watchPartyTemp = location.state?.watchParty ?? null;

    if (!watchPartyTemp) {
      watchPartyTemp = await getWatchParty(watchPartyId ?? "");
    }

    watchPartyTemp.startAt = moment(
      new Date(Number(watchPartyTemp.startAt))
    ).format("MM/DD/YYYY HH:mm:ss");
    watchPartyTemp.endAt = moment(
      new Date(Number(watchPartyTemp.endAt))
    ).format("MM/DD/YYYY HH:mm:ss");

    let questionsArray = Object.values(watchPartyTemp.questions ?? []);
    questionsArray = questionsArray.map((question: any) => {
      const startAt = moment(new Date(Number(question.startAt))).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      const endAt = moment(new Date(Number(question.endAt))).format(
        "MM/DD/YYYY HH:mm:ss"
      );

      return { ...question, startAt, endAt };
    });

    watchPartyTemp.questions = questionsArray;

    setWatchParty(watchPartyTemp);
  };

  useEffect(() => {
    if (watchParty) {
      loadSweepstakes();
    }
  }, [watchParty]);

  useEffect(() => {
    loadWatchParty(location);
  }, [location]);

  return (
    <ContentLayout
      backButton={true}
      onClickBack={onClickBack}
      title={`"${watchParty?.text}" Details`}
    >
      <FormGroup title="Questions">
        {watchParty && questions && isLoading === false && (
          <DetailQuestionsList
            watchParty={watchParty}
            questions={questions}
            ToastMessage={ToastMessage}
            watchPartyId={watchParty.id}
          />
        )}
      </FormGroup>
    </ContentLayout>
  );
};
