import moment from "moment";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useIsAuthenticated} from "react-auth-kit";

import storage from "@/utils/storage";
import { WatchParty } from "../types";
import { useToast } from "@/hooks/useToast";
import { WatchPartyService } from "@/services/WatchPartyService";
import { CustomModal, HeaderObject, ListTable } from "@/components/Elements";
import { ContentLayout } from "@/components/Layout/ContentLayout/ContentLayout";

export const WatchPartyList = () => {
  const ToastMessage = (text: string) => {
    useToast(text);
  };

  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [willPublish, setWillPublish] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState<boolean>(false);
  const [watchParties, setWatchParties] = useState<WatchParty[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
  const [selectedWatchParty, setSelectedWatchParty] = useState<string>("");

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedWatchParty("");
  };

  const closePublishModal = () => {
    setIsPublishModalOpen(false);
    setSelectedWatchParty("");
  };

  const openDeleteModal = (id: string) => {
    setSelectedWatchParty(id);
    setIsDeleteModalOpen(true);
  };

  const openPublishModal = (id: string, publish: boolean) => {
    setSelectedWatchParty(id);
    setIsPublishModalOpen(true);
    setWillPublish(publish);
  };

  const headerList: HeaderObject[] = [
    { label: "Cover Image", name: "imagePath", type: "image" },
    { label: "Title", name: "text", type: "string" },
    { label: "Start at", name: "startAt", type: "string" },
    { label: "End at", name: "endAt", type: "string" },
    { label: "Published", name: "publish", type: "boolean" },
  ];

  const loadWatchParties = async () => {
    setIsLoading(true);
    try {
      const response = await WatchPartyService.listWatchParties();
      let responseArray = Object.values(response);
      responseArray = responseArray.map((watchParty) => {
        const startAt = moment(new Date(Number(watchParty.startAt))).format(
          "MM/DD/YYYY HH:mm:ss"
        );
        const endAt = moment(new Date(Number(watchParty.endAt))).format(
          "MM/DD/YYYY HH:mm:ss"
        );

        return { ...watchParty, startAt, endAt };
      });

      setWatchParties(responseArray);
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveWatchParty = async (id: string) => {
    setIsLoadingDelete(true);
    try {
      await WatchPartyService.deleteWatchParty(id);
      ToastMessage("Watch party deleted successfully.");
      setWatchParties([]);
      loadWatchParties();
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage("Error on deleting the watch party.");
      }
    } finally {
      setIsLoadingDelete(false);
      closeDeleteModal();
    }
  };

  const onEditWatchParty = (id: string) => {
    navigate(`/watch-party/${id}/edit`);
  };

  const onClickAddNew = () => {
    navigate(`/watch-party/create`);
  };

  const onGoToDetails = (watchPartyId: string) => {
    const watchParty = watchParties.find((item) => item.id === watchPartyId);
    navigate(`/watch-party/${watchPartyId}/details`, {
      state: { watchParty: watchParty },
    });
  };

  const publishWatchParty = async (id: string, publish: boolean) => {
    try {
      setIsLoadingPublish(true);

      await WatchPartyService.publishWatchParty({ id, publish });
      ToastMessage("Watch party published successfully.");
      setWatchParties([]);
      loadWatchParties();
    } catch (err) {
      ToastMessage("Error on publishing watch party.");
    } finally {
      setIsLoadingPublish(false);
      closePublishModal();
    }
  };

  const checkToken = () => {
    if (isAuthenticated()) {
      loadWatchParties();
    } else {
      setTimeout(() => checkToken(), 100);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      <CustomModal
        title="Delete watch party?"
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
      >
        <div className="footer">
          <button
            type="button"
            className="btn-outline"
            disabled={isLoadingDelete}
            onClick={closeDeleteModal}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoadingDelete}
            onClick={() => onRemoveWatchParty(selectedWatchParty)}
            className={`btn-primary ${isLoadingDelete ? "loading" : ""}`}
          >
            Delete
          </button>
        </div>
      </CustomModal>
      <CustomModal
        title={willPublish ? "Publish watch party?" : "Unpublish watch party?"}
        isOpen={isPublishModalOpen}
        onRequestClose={closePublishModal}
      >
        <div className="footer">
          <button
            type="button"
            className="btn-outline"
            disabled={isLoadingPublish}
            onClick={closePublishModal}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoadingPublish}
            className={`btn-primary ${isLoadingPublish ? "loading" : ""}`}
            onClick={() => publishWatchParty(selectedWatchParty, willPublish)}
          >
            {willPublish ? "Publish" : "Unpublish"}
          </button>
        </div>
      </CustomModal>
      <ContentLayout
        addNewButton={true}
        onClickAddNew={onClickAddNew}
        title="Watch Parties"
      >
        <ListTable
          listName="Watch Parties"
          isLoading={isLoading}
          headerList={headerList}
          dataArray={watchParties}
          onPublish={openPublishModal}
          onEditItem={onEditWatchParty}
          onGoToDetails={onGoToDetails}
          onRemoveItem={openDeleteModal}
        ></ListTable>
      </ContentLayout>
    </>
  );
};
