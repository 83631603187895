import MaskedInput from "react-text-mask";
import { FieldError, UseFormRegister } from "react-hook-form";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import "./style.css";

const defaultMaskOptions = {
  prefix: "$ ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

type CurrenyInputProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  className?: string;
  registerName: any;
  register: UseFormRegister<any>;
  required: any;
  error?: FieldError;
  isInvalid?: boolean;
};

export const CurrencyInput = ({
  onChange,
  value,
  placeholder,
  className,
  registerName,
  register,
  required,
  error,
  isInvalid,
}: CurrenyInputProps) => {
  const currencyMask = createNumberMask(defaultMaskOptions);
  return (
    <>
      <MaskedInput
        value={value}
        mask={currencyMask}
        placeholder={placeholder}
        {...register(registerName, required)}
        onChange={(e) => onChange(e)}
        className={`${className} form-input ${isInvalid ? "is-invalid" : ""}`}
      ></MaskedInput>
      {error ? <p className="error-message">{error.message}</p> : <></>}
    </>
  );
};
