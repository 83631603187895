import { useEffect } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { SignIn, SignOut } from "@/features/auth";
import { CreateQuestion, EditQuestion } from "@/features/questions";
import {
  CreateWatchParty,
  EditWatchParty,
  WatchPartyList,
  WatchPartyDetails,
} from "@/features/watchParty";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated() === false) {
      return navigate("/sign-in");
    }
    if (location.pathname === "/") {
      navigate("/watch-party");
    }
  }, []);
  return <Outlet></Outlet>;
};

export const protectedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/sign-in", element: <SignIn /> },
      { path: "/sign-out", element: <SignOut /> },
      { path: "/watch-party/:id/details", element: <WatchPartyDetails /> },
      { path: "/watch-party", element: <WatchPartyList /> },
      { path: "/watch-party/create", element: <CreateWatchParty /> },
      { path: "/watch-party/:id/edit", element: <EditWatchParty /> },
      {
        path: "/watch-party/:id/questions/create",
        element: <CreateQuestion />,
      },
      {
        path: "/watch-party/:id/questions/:questionId/edit",
        element: <EditQuestion />,
      },
      { path: "*", element: <Navigate to="." /> },
    ],
  },
]);
