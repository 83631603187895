import { Toaster } from "react-hot-toast";

import Modal from "react-modal";
import { AuthProvider } from "react-auth-kit";
import { RouterProvider } from "react-router-dom";

import { protectedRoutes } from "./routes/protected";

import "@/assets/css/global.css";

Modal.setAppElement("#root");

function App() {
  return (
    <>
      <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
      >
        <RouterProvider router={protectedRoutes}></RouterProvider>
        <Toaster />
      </AuthProvider>
    </>
  );
}

export default App;
