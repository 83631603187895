import Modal from "react-modal";

import closeImg from "@/assets/img/fi-close.svg";

import "./style.css";

type CustomModalProps = {
  onRequestClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  title: string;
  style?: any;
  className?: string;
  disableClose?: boolean;
};

export const CustomModal = ({
  onRequestClose,
  children,
  title,
  isOpen,
  style,
  className,
  disableClose,
}: CustomModalProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    className={`react-modal-content ${className}`}
    overlayClassName="react-modal-overlay"
  >
    <div className="react-modal-container" style={style || {}}>
      <h1>{title}</h1>
      <button
        type="button"
        className="react-modal-close-button"
        onClick={() => onRequestClose()}
        disabled={disableClose}
      >
        <img src={closeImg} alt="Fechar" />
      </button>
      <div className="react-modal-content-wrapper">{children}</div>
    </div>
  </Modal>
);
