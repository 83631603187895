import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { Question } from "@/features/questions";
import { Sweepstake } from "@/features/watchParty";
import { CustomModal } from "@/components/Elements";
import { FormControl, InputField } from "@/components/Form";
import { QuestionService } from "@/services/QuestionService";

import "./style.css";

type SweepstakeModalProps = {
  ToastMessage: (text: string) => void;
  onCloseSweepstakeModal: () => void;
  watchPartyId: string;
  question?: Question;
};

export const SweepstakeModal = ({
  ToastMessage,
  onCloseSweepstakeModal,
  watchPartyId,
  question,
}: SweepstakeModalProps) => {
  const schema = yup
    .object({
      user: yup.string().trim().required("The user ID is required."),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ user: string }>({ resolver: yupResolver(schema) });

  const [isLoadingOverride, setIsLoadingOverride] = useState<boolean>(false);
  const [overrideMode, setOverrideMode] = useState<boolean>(false);
  const [sweepstake, setSweepstake] = useState<Sweepstake>();

  const loadSweepstake = async () => {
    try {
      if (question === undefined) {
        throw new Error("Question not found.");
      }

      const luckyAnswer = await QuestionService.getLuckyAnswers(
        watchPartyId,
        question.id
      );
      setSweepstake({
        questionId: question.id,
        text: question.text,
        imagePath: question.imagePath,
        sweepstake: question.sweepstake,
        luckyAnswer,
      });
    } catch (err) {
      ToastMessage("Error on load Sweepstake.");
    }
  };

  const overrideSweepstake = async (data: { user: string }) => {
    try {
      setIsLoadingOverride(true);

      if (question === undefined) {
        throw new Error("Question not found.");
      }
      await QuestionService.overrideSweepstake({
        questionId: question.id,
        watchPartyId,
        userId: data.user,
      });
      ToastMessage("Sweepstake user overridden successfully.");
    } catch (err) {
      ToastMessage("Error on sweepstake user override.");
    } finally {
      onCloseSweepstakeModal();
      setOverrideMode(false);
    }
  };

  useEffect(() => {
    loadSweepstake();
  }, []);

  return (
    <CustomModal
      isOpen={true}
      title="Sweepstake"
      className="sweepstake-modal"
      onRequestClose={onCloseSweepstakeModal}
    >
      <div className="sweepstake-content">
        <form onSubmit={handleSubmit(overrideSweepstake)}>
          <div className="row">
            <FormControl label="Draw in">
              <span className="sweepstake-property">
                {sweepstake?.luckyAnswer?.drawnIn || "-"}
              </span>
            </FormControl>
            <FormControl label="Username">
              <span className="sweepstake-property">
                {sweepstake?.luckyAnswer?.userName || "-"}
              </span>
            </FormControl>
          </div>
          {overrideMode ? (
            <>
              <FormControl label="User ID">
                <InputField
                  type="text"
                  register={register}
                  error={errors.user}
                  registerName="user"
                  required={{ required: true }}
                  placeholder="Enter User ID here"
                  isInvalid={errors.user !== undefined}
                />
              </FormControl>
              <div className="footer">
                <button
                  type="button"
                  className="btn-outline"
                  disabled={isLoadingOverride}
                  onClick={() => setOverrideMode(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoadingOverride}
                  className={`btn-primary ${
                    isLoadingOverride ? "loading" : ""
                  }`}
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <div className="row" style={{ marginTop: "3rem" }}>
              <button
                type="button"
                onClick={() => setOverrideMode(true)}
                className="btn-primary btn-override"
              >
                Override sweepstake user
              </button>
            </div>
          )}
        </form>
      </div>
    </CustomModal>
  );
};
