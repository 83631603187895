import { useState } from "react";

import { Question } from "@/features/questions";
import { WatchParty } from "@/features/watchParty";
import { QuestionService } from "@/services/QuestionService";
import { SweepstakeModal } from "../SweepstakeModal/SweepstakeModal";

import "./styles.css";
import {WatchPartyService} from "@/services/WatchPartyService";

type SweepstakesListProps = {
  ToastMessage: (text: string) => void;
  watchPartyId: string;
  watchParty: WatchParty;
  questions: Question[];
};

export const DetailQuestionsList = ({
  ToastMessage,
  watchPartyId,
  questions,
}: SweepstakesListProps) => {
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>();
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [isSweepstakeModalOpen, setIsSweepstakeModalOpen] =
    useState<boolean>(false);

  const onMouseEnterSweepstake = (rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grid-options");
    options?.classList.add("shown");
  };

  const onMouseLeaveSweepstake = (rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grid-options");
    options?.classList.remove("shown");
  };

  const onOpenSweepstakeModal = (question: Question) => {
    setSelectedQuestion(question);
    setIsSweepstakeModalOpen(true);
  };

  const onCloseSweepstakeModal = () => {
    setIsSweepstakeModalOpen(false);
  };

  const onExportUsersRanking = async () => {
    try {
      setIsLoadingExport(true);

      const response = await WatchPartyService.exportUsersRanking({
        watchPartyId,
      });

      const href = await URL.createObjectURL(response);
      const link = document.createElement("a");

      link.href = href;
      link.download = `Ranking.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ToastMessage("User's ranking exported successfully.");
    } catch (err) {
      ToastMessage("Error on User's ranking export.");
      console.error(err);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const onExportAnswers = async (question: Question) => {
    try {
      setIsLoadingExport(true);

      const luckyAnswer = await QuestionService.getLuckyAnswers(
        watchPartyId,
        question.id
      );

      const response = await QuestionService.exportAnswersByQuestion({
        watchPartyId,
        questionId: question.id,
        userId: luckyAnswer.userId,
      });

      const href = await URL.createObjectURL(response);
      const link = document.createElement("a");

      link.href = href;
      link.download = `${question.text}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ToastMessage("Answers exported successfully.");
    } catch (err) {
      ToastMessage("Error on answers export.");
      console.error(err);
    } finally {
      setIsLoadingExport(false);
    }
  };

  return (
    <>
      {isSweepstakeModalOpen && (
        <SweepstakeModal
          ToastMessage={ToastMessage}
          watchPartyId={watchPartyId}
          question={selectedQuestion}
          onCloseSweepstakeModal={onCloseSweepstakeModal}
        />
      )}
      <div className="sweepstakes-wrapper">
        <button type="button" className="btn-outline" disabled={isLoadingExport} onClick={onExportUsersRanking}>
          Export users's ranking
        </button>
        <table className="sweepstakes-grid">
          <thead className="grid-header">
            <tr>
              {questions.length ? (
                <>
                  <th>Cover image</th>
                  <th>Question title</th>
                </>
              ) : (
                <th>Questions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {questions.length ? (
              questions.map((question, index) => (
                <tr
                  key={question.id}
                  id={`questions_${index}`}
                  onMouseEnter={() =>
                    onMouseEnterSweepstake(`questions_${index}`)
                  }
                  onMouseLeave={() =>
                    onMouseLeaveSweepstake(`questions_${index}`)
                  }
                >
                  <td>
                    {question.imagePath ? (
                      <img
                        width="2rem"
                        className="question-img"
                        src={question.imagePath}
                        alt="Question cover"
                      />
                    ) : (
                      "Doesn't have cover image"
                    )}
                    <div className="grid-options">
                      <button
                        type="button"
                        disabled={isLoadingExport}
                        className={`grid-option-btn ${
                          isLoadingExport && "loading"
                        }`}
                        onClick={() => onExportAnswers(question)}
                      >
                        Export answers
                      </button>
                      {!!question.sweepstake && (
                        <>
                          |
                          <button
                            type="button"
                            className="grid-option-btn"
                            onClick={() => onOpenSweepstakeModal(question)}
                          >
                            Sweepstake
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                  <td>{question.text}</td>
                </tr>
              ))
            ) : (
              <tr className="no-items-found">
                <td>No items found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
