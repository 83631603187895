import { useState } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { WatchParty } from "../../types";
import { Question } from "@/features/questions";
import { CustomModal } from "@/components/Elements";
import { QuestionService } from "@/services/QuestionService";
import { ImportQuestionsModal } from "../ImportQuestionsModal/ImportQuestionsModal";

import "./style.css";

type WatchPartyQuestionsProps = {
  ToastMessage: (text: string) => void;
  updateQuestions: (watchPartyId: string) => void;
  onDeleteQuestion: (id: string) => void;
  questions: Question[];
  watchPartyId: string;
  watchParty: WatchParty;
};

export const WatchPartyQuestions = ({
  ToastMessage,
  updateQuestions,
  onDeleteQuestion,
  questions,
  watchPartyId,
  watchParty,
}: WatchPartyQuestionsProps) => {
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [questionsFile, setQuestionsFile] = useState<File | undefined>();

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedQuestion("");
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setQuestionsFile(undefined);
  };

  const openDeleteModal = (id: string) => {
    setSelectedQuestion(id);
    setIsDeleteModalOpen(true);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const onMouseEnterQuestion = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grid-options");
    options?.classList.add("shown");
  };

  const onMouseLeaveQuestion = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grid-options");
    options?.classList.remove("shown");
  };

  const addNewQuestion = (watchPartyId: string) => {
    navigate(`/watch-party/${watchPartyId}/questions/create`, {
      state: { watchParty: watchParty },
    });
  };

  const removeQuestion = async (id: string) => {
    setIsLoadingDelete(true);
    try {
      await QuestionService.deleteQuestion(id, watchPartyId);
      ToastMessage("Question deleted successfully.");
      onDeleteQuestion(id);
    } catch (err) {
      const error = err as AxiosError<any>;
      // @ts-ignore
      if (
        error.response?.data?.message === "Validation errors" &&
        error.response?.data?.data
      ) {
        // @ts-ignore
        const errors = Object.values(
          error.response.data.data
        ).flat() as string[];
        ToastMessage(errors.join("\n"));
      } else {
        ToastMessage("Error on deleting the question.");
      }
    } finally {
      setIsLoadingDelete(false);
      closeDeleteModal();
    }
  };

  const editQuestion = (id: string) => {
    navigate(`/watch-party/${watchPartyId}/questions/${id}/edit`, {
      state: { watchParty: watchParty },
    });
  };

  return (
    <>
      <ImportQuestionsModal
        ToastMessage={ToastMessage}
        updateQuestions={updateQuestions}
        closeUploadModal={closeUploadModal}
        setQuestionsFile={setQuestionsFile}
        setIsLoadingUpload={setIsLoadingUpload}
        watchPartyId={watchPartyId}
        questionsFile={questionsFile}
        isLoadingUpload={isLoadingUpload}
        isUploadModalOpen={isUploadModalOpen}
      />
      <CustomModal
        title="Delete question?"
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
      >
        <div className="footer">
          <button
            type="button"
            className="btn-outline"
            onClick={closeDeleteModal}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoadingDelete}
            onClick={() => removeQuestion(selectedQuestion)}
            className={`btn-primary ${isLoadingDelete ? "loading" : ""}`}
          >
            Delete
          </button>
        </div>
      </CustomModal>
      <div className="questions-wrapper">
        <div className="row">
          {watchParty.questions.length < 1 ? (
            <button
              type="button"
              onClick={openUploadModal}
              className="btn-outline btn-import"
            >
              Import questions as CSV
            </button>
          ) : (
            <></>
          )}
          <button
            type="button"
            className="btn-outline"
            onClick={() => addNewQuestion(watchPartyId)}
          >
            Add new question
          </button>
        </div>
        <table className="questions-grid">
          <thead className="grid-header">
            <tr>
              {questions.length ? (
                <>
                  <th>Cover image</th>
                  <th>Question title</th>
                  <th>Start at</th>
                  <th>End at</th>
                </>
              ) : (
                <th>Questions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {questions.length ? (
              questions.map((question, index) => (
                <tr
                  key={question.id}
                  id={`question_${index}`}
                  onMouseEnter={() =>
                    onMouseEnterQuestion(index, `question_${index}`)
                  }
                  onMouseLeave={() =>
                    onMouseLeaveQuestion(index, `question_${index}`)
                  }
                >
                  <td>
                    {question.imagePath ? (
                      <img
                        className="question-img"
                        src={question.imagePath}
                        alt="Question cover"
                      />
                    ) : (
                      "Doesn't have cover image"
                    )}
                    <div className="grid-options">
                      <button
                        type="button"
                        className="grid-option-btn"
                        onClick={() => editQuestion(question.id)}
                      >
                        Edit
                      </button>
                      |
                      <button
                        type="button"
                        className="grid-option-btn delete"
                        onClick={() => openDeleteModal(question.id)}
                      >
                        Trash
                      </button>
                    </div>
                  </td>
                  <td>{question.text}</td>
                  <td>{question.startAt}</td>
                  <td>{question.endAt}</td>
                </tr>
              ))
            ) : (
              <tr className="no-items-found">
                <td>No items found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
