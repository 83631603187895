import "./style.css";

export type HeaderObject = {
  label: string;
  name: string;
  type: "image" | "string" | "boolean";
};

type ListTableProps = {
  onRemoveItem: (id: string) => void;
  onEditItem: (id: string) => void;
  onPublish: (id: string, value: boolean) => void;
  onGoToDetails: (id: string) => void;
  headerList: HeaderObject[];
  dataArray: any[];
  isLoading: boolean;
  listName: string;
};

export const ListTable = ({
  onRemoveItem,
  onEditItem,
  onPublish,
  onGoToDetails,
  headerList,
  dataArray,
  isLoading,
  listName,
}: ListTableProps) => {
  const onMouseEnterRow = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grid-options");
    options?.classList.add("shown");
  };

  const onMouseLeaveRow = (index: number, rowId: string) => {
    const row = document.querySelector(`#${rowId}`);
    const options = row?.querySelector(".grid-options");
    options?.classList.remove("shown");
  };

  const renderColumn = (item: any, field: HeaderObject) => {
    if (field.type === "image") {
      return (
        <img src={item[field.name]} className="item-img" width="2rem" alt="" />
      );
    }

    if (field.type === "boolean") {
      return (
        <>
          {item.publish ? (
            <div
              title="Unpublish watch party"
              className="pointer check-icon"
              onClick={() => onPublish(item.id, false)}
              style={{ width: "20px", height: "20px", margin: "0 auto" }}
            />
          ) : (
            <div
              title="Publish watch party"
              className="pointer xmark-icon"
              onClick={() => onPublish(item.id, true)}
              style={{ width: "20px", height: "20px", margin: "0 auto" }}
            />
          )}
        </>
      );
    }

    return item[field.name];
  };

  return (
    <div className="list-wrapper">
      <table className="table-list">
        <thead className="table-header">
          <tr>
            {dataArray.length && isLoading === false ? (
              headerList.map((item, index) => <th key={index}>{item.label}</th>)
            ) : (
              <th>{listName}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {dataArray.length ? (
            dataArray.map((item, index) => (
              <tr
                key={item.id}
                id={`table-item_${index}`}
                onMouseEnter={() =>
                  onMouseEnterRow(index, `table-item_${index}`)
                }
                onMouseLeave={() =>
                  onMouseLeaveRow(index, `table-item_${index}`)
                }
              >
                {headerList.map((field, index) => (
                  <td key={index}>
                    {renderColumn(item, field)}
                    {index === 0 && (
                      <div className="grid-options">
                        <button
                          type="button"
                          className="grid-option-btn"
                          onClick={() => onEditItem(item.id)}
                        >
                          Edit
                        </button>
                        |
                        <button
                          type="button"
                          className="grid-option-btn"
                          onClick={() => onGoToDetails(item.id)}
                        >
                          Details
                        </button>
                        |
                        <button
                          type="button"
                          className="grid-option-btn delete"
                          onClick={() => onRemoveItem(item.id)}
                        >
                          Trash
                        </button>
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : isLoading ? (
            <tr className="is-loading">
              <td>Loading...</td>
            </tr>
          ) : (
            <tr className="no-items-found">
              <td>No items found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
