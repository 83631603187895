import { CustomModal } from "@/components/Elements";
import { QuestionService } from "@/services/QuestionService";
import { QuestionsUpload } from "../QuestionsUpload/QuestionsUpload";

import "./style.css";

type ImportQuestionsModalProps = {
  ToastMessage: (text: string) => void;
  updateQuestions: (watchPartyId: string) => void;
  closeUploadModal: () => void;
  setQuestionsFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setIsLoadingUpload: (value: boolean) => void;
  watchPartyId: string;
  questionsFile?: File;
  isLoadingUpload: boolean;
  isUploadModalOpen: boolean;
};

export const ImportQuestionsModal = ({
  ToastMessage,
  updateQuestions,
  closeUploadModal,
  setQuestionsFile,
  setIsLoadingUpload,
  watchPartyId,
  questionsFile,
  isLoadingUpload,
  isUploadModalOpen,
}: ImportQuestionsModalProps) => {
  const uploadQuestions = async () => {
    setIsLoadingUpload(true);

    try {
      if (questionsFile === undefined) {
        throw new Error("No file selected.");
      }

      const formData = new FormData();
      formData.append("questions", questionsFile);

      await QuestionService.importQuestions(watchPartyId, formData);
      ToastMessage("Import done successfully.");
      updateQuestions(watchPartyId);
    } catch (err) {
      const error = err as Error;
      ToastMessage(error.message);
    } finally {
      setIsLoadingUpload(false);
      closeUploadModal();
    }
  };

  return (
    <CustomModal
      title="Import questions"
      isOpen={isUploadModalOpen}
      onRequestClose={closeUploadModal}
    >
      <div className="wrapper">
        <QuestionsUpload
          id="questions-upload"
          ToastMessage={ToastMessage}
          setQuestionsFile={setQuestionsFile}
          questionsFile={questionsFile}
          isLoadingUpload={isLoadingUpload}
        ></QuestionsUpload>
      </div>
      {questionsFile ? (
        <div className="footer">
          <button
            type="button"
            onClick={() => uploadQuestions()}
            disabled={isLoadingUpload}
            className={`btn-primary ${
              isLoadingUpload ? "loading" : ""
            } btn-upload-questions`}
          >
            Upload
          </button>
        </div>
      ) : (
        <></>
      )}
    </CustomModal>
  );
};
