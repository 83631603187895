const storagePrefix = "uptv_watchparty_cms_";

const storage = {
  getToken: () => {
    return document.cookie.replace(/(?:^|.*;\s*)_auth\s*=\s*([^;]*).*$|^.*$/, "$1") as string;
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};

export default storage;
