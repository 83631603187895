import { SignOutComponent } from "@/features/auth/components/signOut/SiginOutComponent";
import * as React from "react";

import "./style.css";

type ContentLayoutProps = {
  onClickAddNew?: () => void;
  onClickBack?: () => void;
  children: React.ReactNode;
  title: string;
  addNewButton?: boolean;
  backButton?: boolean;
};

export const ContentLayout = ({
  onClickAddNew,
  onClickBack,
  children,
  title,
  addNewButton,
  backButton,
}: ContentLayoutProps) => {
  return (
    <>
      <div className="content-wrapper">
        <header className="page-header">
          <h1 className="page-title">
            {title}
            {addNewButton ? (
              <button
                className="btn-outline"
                onClick={onClickAddNew}
                style={{ marginLeft: ".5rem", borderRadius: "2px" }}
              >
                Add new
              </button>
            ) : (
              <></>
            )}
          </h1>
          {backButton ? (
            <button className="btn-outline" onClick={onClickBack}>
              Back
            </button>
          ) : (
            <></>
          )}
          <SignOutComponent />
        </header>
        <div className="content-container">{children}</div>
      </div>
    </>
  );
};
